<template>
  <div>
    <v-row>
    <v-col class="pr-0">
    <s-crud
      title="Usuarios"
      :config="config"
      :add="isCrud" height="350"
      :edit="isCrud"
      ref="gridUser"
      :remove="isCrud"
      :filter="filter"
      @save="saveUsr($event)"
      @rowSelected="rowSelected"
      sortable
      searchInput
      :singleRow="singleRow"
    >
      <template v-if="isCrud" v-slot:options>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" small elevation="0" @click="copyAllow">
              <v-icon color="default" x-small>
                fas fa-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Copiar a</span>
        </v-tooltip>
      </template>
      <template v-if="isCrud" slot="filter">
        <v-col class=" pb-0">
        <v-row class="justify-center">
          <v-col  class="pt-0" offset="1" cols="5">
            <v-switch label="Solo usuarios activos" v-model="filter.UsrStatus" dense hide-details :false-value="0" :true-value="1" />
          </v-col>
          <v-col  class="pt-0 " cols="5">
            <v-switch label="Solo registros válidos" v-model="filter.SecStatus" dense hide-details :false-value="0" :true-value="1" />
          </v-col>
        </v-row>
        </v-col>
      </template>
      <template v-if="isCrud" v-slot:default="{ item }">
        <v-container>
          <v-row>
            <v-col cols="5" class="s-col-form">
              <s-text label="Usuario" v-model="item.UsrName" autofocus />
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-date label="F. Expiracion" v-model="item.ExpirationDate" clearable></s-date>
            </v-col>
            <v-col cols="1" class="s-col-form">
              <s-switch label="Activo" v-model="item.UsrStatus" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="s-col-form">
              <s-toolbar-person noCamera :defaultDocument="item.PrsDocumentNumber" @returnPerson="returnPerson($event, item)" />
            </v-col>

            <v-col cols="7" class="s-col-form">
              <s-text readonly label="Persona" v-model="item.NtpFullName">
                <template v-slot:append-outer>
                  <v-icon
                    color="error"
                    style="margin-top:4px; cursor: pointer;"
                    @click="
                      item.NtpFullName = null;
                      item.NtpID = null;
                    "
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </template>
              </s-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="s-col-form">
              <s-switch
                :label="'Establecer contraseña por defecto: ' + item.UsrName.toLowerCase()"
                :true-value="item.UsrName.toLowerCase()"
                :false-value="''"
                v-model="item.UsrPassword"
                @change="changePassword(item, $event)"
              />
            </v-col>
            <v-col cols="6" class="s-col-form">
              <s-switch label="Solicitar cambio en próximo inicio de sesión" v-model="item.UsrPasswordChange" :true-value="1" :false-value="0" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:UsrStatus="{ row }">
        <v-icon x-small :color="row.UsrStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
      </template>
      <template v-slot:SecStatus="{ row }">
        <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
      </template>
    </s-crud>
    </v-col>
    </v-row>
    <v-dialog v-model="showDialogCopy" max-width="500">
      <v-card>
        <v-card-title>
          <label>
            Seleccione a quienes copiará los permisos de <strong>{{ (userSelected.UsrName || "").toUpperCase() }}</strong></label
          >
        </v-card-title>

        <v-card-text>
          <s-user v-model="usersCopy" ref="usrcopy" :isCrud="false" :singleRow="false" />
        </v-card-text>

        <v-card-actions>
          <v-btn color="default " small text @click="closeDialogCopy()">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark depressed small @click="saveCopyAllow()">Copiar permisos </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sUser from "@/services/Security/UserService";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
  name: "s-user",
  components: { sToolbarPerson },
  props: {
    singleRow: { type: Boolean, default: true },
    isCrud: { type: Boolean, default: true },
  },
  data: () => ({
    options: [],
    userSelected: {},
    usersCopy: [],
    person: {},
    filter: {
      UsrStatus: 1,
      SecStatus: 1,
    },
    passwordValid: "",
    passwordRepeat: "",
    showDialogPassword: false,
    parentID: 0,
    showDialogCopy: false,
  }),
  computed: {
    config() {
      return {
        model: {
          UsrName: "string",
          UsrID: "ID",
          NtpID: "int",
          NtpFullName: "string",
          PrsDocumentNumber: "string",
          SecStatus: "status",
          UsrStatus: "boolean",
          UsrPassword: "string",
          UsrPasswordChange: "boolean",
          ExpirationDate: "date",
        },
        service: _sUser,
        headers: this.isCrud
          ? [
              { text: "ID", value: "UsrID", align: "end", },
              { text: "Usuario", value: "UsrName", },
              { text: "Persona", value: "NtpFullName" },
              { text: "F.Expi", value: "ExpirationDate" },
              { width: "5%", text: "Est", value: "UsrStatus", sortable: false, }, 
              { width: "5%", text: "Sec", value: "SecStatus", sortable: false, },
            ]
          : [
              { text: "Usuario", value: "UsrName", },
              { text: "Persona", value: "NtpFullName" },
            ],
      };
    },
  },

  methods: {
    changePassword(item, e) {
      item.UsrPasswordChange = item.UsrPassword != "" && item.UsrPassword != null ? 0 : 1;
    },
    removeUser() {
      this.person = null;
    },
    validatePassword() {
      let isValid;
      let message;

      isValid = this.passwordValid.length > 6 && this.passwordRepeat.length > 6;
      if (!isValid) message = "longitud mínima de contraseña 8 caracteres.";
      else {
        isValid = this.passwordValid == this.passwordRepeat;

        if (!isValid) message = "Contraseñas no coindicen.";
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    rowSelected(items) {
      if (items.length > 0) this.userSelected = this.isCrud ? items[0] : items;
      else this.userSelected = this.isCrud ? {} : [];

      this.$emit("input", Object.assign({}, this.userSelected));
    },
    returnPerson(value, item) {
      item.NtpFullName = value.NtpFullName;
      item.NtpID = value.NtpID;
    },
    validatedUsr(item) {
      let isValid = false;
      let message = "";

      isValid = item.UsrName.length > 0;
      if (!isValid) message = "Ingrese Nombre de Usuario";

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveUsr(item) {
      if (this.validatedUsr(item)) {
        item.save();
      }
    },
    copyAllow() {
      this.showDialogCopy = true;
      this.usersCopy = [];
      this.$refs.usrcopy.refresh();
    },
    closeDialogCopy() {
      this.showDialogCopy = false;
    },
    refresh() {
      this.$refs.gridUser.restart();
    },
    saveCopyAllow() {
      this.usersCopy = Object.assign([], this.usersCopy).filter((x) => x.UsrID != this.userSelected.UsrID);

      _sUser.copyAllow(this.userSelected, this.usersCopy, this.$fun.getUserID()).then((r) => {
        this.$fun.alert("Permisos actualizados correctamente.", "success");
      });
    },
  },
};
</script>
